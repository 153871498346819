import { andyFetch, actionReturn } from '../../../api';

export const getAccountStructures = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/account_structures`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};