import { andyFetch, actionReturn } from '../../../api';


export const deleteExpenseHeader = (params) => {
  return async (dispatch, getState) => {
    const url = "manager/expense_headers/delete";
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();
        return actionReturn(json);
      } else {
        throw new Error('deleteExpenseHeader error.');
      }
    }
    catch (e) {
    }
  }
};

export const getApprovalSteps = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/expense_headers/steps`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getApprovals = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/expense_headers/expense_approvals`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getStatuses = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/expense_headers/statuses`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getExpenseCategories = (params) => {
  return async (dispatch, getState) => {
    const url = `expense_categories`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getTaxes = (params) => {
  return async (dispatch, getState) => {
    const url = `tax_groups`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getApprovedExpenseHeaders = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/expense_headers/approved`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};


export const getSubmittedExpenseHeaders = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/expense_headers/submitted`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getMyExpenseHeaders = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/expense_headers/mine`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getAllExpenseHeaders = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/expense_headers`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getReviewCount = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/expense_headers/review_count`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        console.log('json.data', json.data);

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const getExpenseHeader = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/expense_headers/show_header`;
    try {
      const response = await andyFetch(url, params, "GET");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const updateExpenseHeader = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/expense_headers/${params?.payload?.id}`;
    try {
      const response = await andyFetch(url, params, "PATCH");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const createExpenseHeader = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/expense_headers`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const submitExpenseHeader = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/expense_headers/submit`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const approveExpenseHeader = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/expense_headers/approve`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

export const returnExpenseHeader = (params) => {
  return async (dispatch, getState) => {
    const url = `manager/expense_headers/return`;
    try {
      const response = await andyFetch(url, params, "POST");
      if (response.ok) {
        const json = await response.json();

        return actionReturn(json);
      } else {
        throw new Error('There was an error.');
      }
    }
    catch (e) {
      
    }
  }
};

