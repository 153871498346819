import React, { useEffect, useState, useRef } from 'react';

import { Controller } from 'react-hook-form';

import DatePicker from 'react-date-picker';
// import '../../styles/Calendar.scss';


import { useApi } from '../../api';
import { connect } from 'react-redux';

import store from '../../redux/store';

import { isNumeric } from '../../utils';
import Modal from '../../components/Modal';
import { saveBudgetHeader, clearBudgetHeader, addBudgetLineTask, getBudgetHeader, removeBudgetLineTask, createBudgetLineTask, deleteBudgetLineTask} from '../../redux/manager/budgetHeaders/budgetHeaderActions';

import { DateTime } from 'luxon';
import { useWatch } from "react-hook-form";


const BudgetTaskRow = ({currencySymbol, percentageBilling, milestonePaid, totalHoursConsumed, projPosted, stats, balanceNegative, milestoneFee, milestoneBilled, reset, key, updateValues, control, setRemovedIndexes, removedIndexes, setValue, readOnly, watch, getValues, rrr, trigger, formState, errors, lineIndex, task, unregister, register, index, taskErrors, budgetHeader}) => {

  const [taskName, setTaskName] = useState(task?.name);
  const [destroyed, setDestroyed] = useState(false);

  const taskIndex = index + 0;
  const inputName = `budget_lines.${lineIndex}.budget_line_tasks.${taskIndex}`;

  let w = useWatch({
    control,
    name: `${inputName}.hoursrequired`, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: task?.hoursrequired
    // default value before the render
  });

  useEffect(() => {
    if (task) {
      // setValue(`budget_lines.${lineIndex}.budget_line_tasks.${taskIndex}._destroy`, null)
      // reset({...getValues(), [`budget_lines.${lineIndex}.budget_line_tasks.${taskIndex}._destroy`]: null}) 

      
    }
  }, [task])


  const inputUpdated = (value) => {
    // updateValues(value, lineIndex, index);
  }



  const [deletedTask, deleteTaskLoading, deleteTaskLoaded, deleteTaskError, doDeleteTaskFetch, setDeleteTaskResult] = useApi();

  const deleteLineTaskClicked = () => {
    setRemovedIndexes(prevRemovedIndexes => [...prevRemovedIndexes, index]);
    const fn = `${inputName}._destroy`;
    setValue(fn, "1");
    setValue(`${inputName}.hoursrequired`, '');
    setDestroyed(true);
  }

  // const myErrors = () => {
  //   return errors?.budget_lines?.[lineIndex]?.budget_line_tasks?.[index]
  // }'

  const [nameErrors, setNameErrors] = useState(null);

  const finalHoursConsumed = () => {
    if (!task) return 0;

    return task.finalhoursconsumed;
  }


  const calcBalanceOfHours = () => {
    
    // let r = 0;
    // if (finalHoursConsumed()) return 0;
    if (!w) return (0.0 - parseFloat(finalHoursConsumed())).toFixed(2);
    // if (!w) return (parseFloat(task?.originalhours) - parseFloat(finalHoursConsumed())).toFixed(2);

    const parsed = parseFloat(w);
    if (isNaN(parsed)) return (0.0 - parseFloat(finalHoursConsumed())).toFixed(2);
    // if (isNaN(parsed)) return (parseFloat(task?.originalhours) - parseFloat(finalHoursConsumed())).toFixed(2);

    return (parsed - parseFloat(finalHoursConsumed())).toFixed(2);
    // setValue(`${inputName}.hoursbalance`, r);

    // return r;
  }

  const balanceOfHours = calcBalanceOfHours();
  const balanceOfHoursNegative = balanceOfHours !== null && parseFloat(balanceOfHours) < 0;


  const postedAmount = () => {

    if (budgetHeader?.avghourlyrate == null || projPosted == null) {
      return null;
    }

    return parseFloat(budgetHeader?.avghourlyrate) * parseFloat(projPosted);
  }
  // if (!task) return null;

  const canDelete = () => {
    if (readOnly) return false;
    if (deleteTaskLoading) return false;
    if (!task) return true;

    if (task) {

      if ((isNumeric(task.finalhoursconsumed) && parseFloat(task.finalhoursconsumed) !== 0 ) ||
          (isNumeric(task.hourspending) && parseFloat(task.hourspending) !== 0   )) {
        return false;
      }
      
    }
    return true;
  }

  const [removeModalInfoOpened, setRemoveInfoModalOpened] = useState(false);

  return (
    <React.Fragment key={key}>


      <td className="width-taskname">

      {/* <div onClick={() => {
        reset({
        [`${inputName}.hoursrequired`]: task?.hoursrequired
        
      })
      trigger();
      
      
      }} className="button">

      </div> */}

      {removeModalInfoOpened &&
        <Modal size="small" focusTrap={false} toggleModal={() => setRemoveInfoModalOpened(false)}>
          <div className="modal-header flex-cont align-center">


            <div className="flex-1"></div>
            <div onClick={() => setRemoveInfoModalOpened(false)} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>
          <div className="padding-1">
            Unable to remove this task because it is being used in existing timesheets.
          </div>
        </Modal>
      }

      



      <label  key={key} className={`${errors?.budget_lines?.[lineIndex]?.budget_line_tasks?.[taskIndex]?.taskname ? 'error-label' : ''}`}>
      {/* <div>
      parseFloat(task.hoursconsumed): {JSON.stringify(parseFloat(task.hoursconsumed) === 0 , null, 2)}
            </div> */}
            {/* <div>
              0.0: {JSON.stringify(task.hoursconsumed === 0.0, null, 2)}
            </div>
            <div>
              false: {JSON.stringify(task.hoursconsumed === false, null, 2)}
            </div>
            <div>
              null: {JSON.stringify(task.hoursconsumed === null, null, 2)}
            </div>
            <div>
              undefined: {JSON.stringify(task.hoursconsumed === undefined, null, 2)}
            </div>
            <div>
              "0": {JSON.stringify(task.hoursconsumed === "0", null, 2)}
            </div>
            <div>
              "0.0": {JSON.stringify(task.hoursconsumed === "0.0", null, 2)}
            </div> */}
          <div className={`flex-cont align-center width-taskname`}>
          {/* `${inputName}[taskname]` */}
            {/* <input ref={register()} type="hidden" name={`${inputName}[id]`} value={task.id} />
            <input ref={register()} type="hidden" name={`${inputName}[budgetlineid]`} value={task.budgetLineID} /> */}
            {/* <div>Task ERRORS: {JSON.stringify(errors?.budget_lines?.[lineIndex]?.budget_line_tasks?.[index]?.taskname)}</div> */}

            

            <div>
              {deleteTaskLoading && <div className="margin-right-half font-05 spinner"></div> }
              { canDelete() && <i style={{paddingLeft: '2px'}} onClick={deleteLineTaskClicked} className="fas fa-times margin-right-half gray icon-button"></i>}
              { !canDelete() && !readOnly && <i onClick={() => setRemoveInfoModalOpened(true)} className="fas fa-info-circle margin-right-half light-gray icon-button" style={{paddingRight: '0'}}></i>}
            </div>
            <input  {...register(`${inputName}._destroy`)} type="hidden" name={`${inputName}._destroy`} />
            <input  {...register(`${inputName}.id`)} type="hidden" name={`${inputName}.id`} value={task?.id} />
            <input onFocus={e => readOnly && e.target.blur()} className={`${(deleteTaskLoading || readOnly) && 'disabled-input'} width-taskname`} onChange={() => rrr()}  key={task?.id} {...register(`${inputName}.taskname`, {
              validate: v => {
                if (destroyed) return true;
                if (!v) return "Enter a name."
                return true;
              }
            })} defaultValue={taskName}  type="text" name={`${inputName}.taskname`} id=""/>
            
          </div>
          {/* {errors?.budget_lines?.[lineIndex]?.budget_line_tasks?.[index] &&
            <div className="error-cont">{errors?.budget_lines?.[lineIndex]?.budget_line_tasks?.[index]}</div>
          } */}
          {errors?.budget_lines?.[lineIndex]?.budget_line_tasks?.[taskIndex]?.taskname &&
            <div className="error-cont">{errors?.budget_lines?.[lineIndex]?.budget_line_tasks?.[taskIndex]?.taskname?.message}</div>
          }
        </label>

          {/* <div>{w}</div> */}
      </td>
      <td className="width-hours">
        {true &&
          <>
            {(isNaN(parseFloat(task?.originalhours))) ? (
              <div className="calc">
                -
              </div>
            ) : (
                <div className="calc">
                  {task?.originalhours}
                </div>
            )}

            <input {...register(`${inputName}.originalhours`)} defaultValue={task?.originalhours} type="hidden" name={`${inputName}.originalhours`} id="" className={`${(readOnly) && 'disabled-input'} `} />
          </>
        }
      
        {false && budgetHeader?.budgetheaderstatusid?.toString() === "0" &&
          <>
            <input {...register(`${inputName}.originalhours`)} defaultValue={task?.originalhours} type="text" name={`${inputName}.originalhours`} id="" className={`${(true || readOnly) && 'disabled-input'} `}/>
            {errors?.budget_lines?.[lineIndex]?.budget_line_tasks?.[taskIndex]?.originalhours &&
              <div className="error-cont">{errors?.budget_lines?.[lineIndex]?.budget_line_tasks?.[taskIndex]?.originalhours?.message}</div>
            }
          </>
        }
        {false && budgetHeader?.budgetheaderstatusid?.toString() !== "0" &&

          <>
            {/* <div className="calc">
              {task?.originalhours}
            </div> */}

            {(!task?.originalhours) ? (
              <div className="calc">
                -
              </div>
            ) : (
                <div className="calc">
                  {task?.originalhours}
                </div>
            )}

            <input {...register(`${inputName}.originalhours`)} defaultValue={task?.originalhours} type="hidden" name={`${inputName}.originalhours`} id="" className={`${(readOnly) && 'disabled-input'} `} />
          </>
        }
      </td>
      <td className="width-hours">

        <input {...register(`${inputName}.finalhoursconsumed`)} defaultValue={task?.finalhoursconsumed} type="hidden" name={`${inputName}.finalhoursconsumed`} id="" className={`${(readOnly) && 'disabled-input'} `} />

        <div className="calc">
          {task?.finalhoursconsumed}
        </div>
        {/* <div>
          <pre>
            {JSON.stringify(task, null, 2)}
          </pre>
        </div> */}

      </td>
      <td className="width-hours">
        {/* HOURS PENDING */}
        {/* budgetHeader?.budgetheaderstatusid?.toString() === "0" ||  */}
        {/* <pre>
          {task?.id}
        </pre>
        <pre>
          {JSON.stringify(stats.pending[task?.id], null, 2)}
        </pre> */}

        {(!task?.hourspending) ? (
          <div className="calc">
            -
          </div>
        ) : (
            <div className="calc">
              {(task?.hourspending)}
            </div>
        )}
        
      </td>
      <td  className={`width-hours ${balanceOfHoursNegative  ? 'negative-red' : ''}`}>
        {/* Balance of hours */}

        {budgetHeader?.budgetheaderstatusid?.toString() === "0" &&
          <div className="calc">
            -
          </div>
        }
        {budgetHeader?.budgetheaderstatusid?.toString() !== "0" &&
          <>
          <div>
          <input {...register(`${inputName}.hoursbalance`)} defaultValue={calcBalanceOfHours()} type="hidden" name={`${inputName}.hoursbalance`} id="" className={`${(readOnly) && 'disabled-input'} `} />
          
          </div>
          <div className="calc">
            {calcBalanceOfHours()}
            {/* {ov ? ov : task?.originalhours} */}
            {/* {balanceOfHours ? balanceOfHours : "-"} */}

            

            {/*  */}
            
          </div>
          </>
        }
      </td>
      <td className={`width-hours ${(balanceNegative || balanceOfHoursNegative) ? 'negative-red' : ''}`}>
            {/* <div className="calc">
              {JSON.stringify(w, null, 2)}
            </div> */}
        {false && budgetHeader?.budgetheaderstatusid?.toString() === "0" &&
          <div className="calc">
            -
          </div>
        }
        {true && <>
          <input  className={`${(deleteTaskLoading || readOnly) && 'disabled-input'}`}  key={task?.id} {...register(`${inputName}.hoursrequired`, {
              validate: v => {
                // if (destroyed) return true;
                // if (!v) return "Enter a name."

                if (destroyed) return true;
                // if (v == null) return "Enter a number."
                if (v == null) return "Enter a number."
                if (v.length == 0) return "Enter a number."

                // const vv = v.replaceAll(',', '');
                const vv = v.toString().replace(/,/g, '');

                if (vv == null) return "Enter a number."
                if (vv.length == 0) return "Enter a number."
                if (!isNumeric(vv)) return "Enter a number."
                if (parseFloat(vv) < 0) return "Please enter a non-negative number."

                if ((parseFloat(vv) * 100) % 25 !== 0) return "Enter a number in 0.25 increments."

                return true;
              }
            })} defaultValue={task?.hoursrequired?.toLocaleString('us-en')}  type="text" name={`${inputName}.hoursrequired`} id=""/>
          {/* <input {...register(`${inputName}.hoursrequired`, {
              validate: v => {
                if (destroyed) return true;
                if (!v) return "Enter a number."
                if (!isNumeric(v)) return "Enter a number."
                if (parseFloat(v) <= 0) return "Enter a number greater than 0."
                return true;
              }
            }
            )} defaultValue={task?.hoursrequired} type="text" name={`${inputName}.hoursrequired`} id="" className={`${(readOnly) && 'disabled-input'} `} /> */}
        </>}
        {errors?.budget_lines?.[lineIndex]?.budget_line_tasks?.[taskIndex]?.hoursrequired &&
          <div className="error-cont">{errors?.budget_lines?.[lineIndex]?.budget_line_tasks?.[taskIndex]?.hoursrequired?.message}</div>
        }
        {/* budgetHeader?.budgetheaderstatusid?.toString() !== "0" */} 
        
      </td>

      
      <td  className="width-date">

        <input 
          type="date" 
          defaultValue={task?.estcompleteddate || getValues('targetdate')}
          // disabled={readOnly} 
          {...register(`${inputName}.estcompleteddate`, {
            validate: v => {
              if (destroyed) return true;
              if (!v) return "Enter a date."
              return true;
            }
          })}
          className={`${(readOnly) && 'disabled-input'} `}
        />


        {errors?.budget_lines?.[lineIndex]?.budget_line_tasks?.[taskIndex]?.estcompleteddate &&
          <div className="error-cont">{errors?.budget_lines?.[lineIndex]?.budget_line_tasks?.[taskIndex]?.estcompleteddate?.message}</div>
        }
      </td>
      <td   className="width-hours">
        <div className="calc">
          -
        </div>
      </td>
      <td   className="width-hours">
        {/* <input type="text" name="" id="" className=""/> */}

        {(budgetHeader?.budgetheaderstatusid?.toString() === "0") ? (
          <div className="calc">
            -
          </div>
        ) : (
          <>
            <div style={{position: 'relative'}}>
              <div className="calc">
                {percentageBilling}%
              </div>
              {/* <input  className={`${readOnly && 'disabled-input'}`}  {...register(`${inputName}.billingpct`)} defaultValue={task?.billingpct} type="text" name={`${inputName}.billingpct`}  style={{paddingRight: '2em'}} />
              <div className="input-icon" style={{position: 'absolute', right: '8px', top: '5px'}}>
                %
              </div> */}
            </div>
          </>
        )}
      </td>
      <td className={`width-date`}>
        {(budgetHeader?.budgetheaderstatusid?.toString() === "0") ? (
          <div className="calc">
            -
          </div>
        ) : (
          <>
          <input 
            type="date" 
            defaultValue={task?.completeddate}
            // disabled={readOnly} 
            {...register(`${inputName}.completeddate`, {
              validate: v => {
                // if (destroyed) return true;
                // if (!v) return "Enter a date."
                return true;
              }
            })}
            className={`${(readOnly) && 'disabled-input'}`}
          />

         
            {/* <input disabled={readOnly || budgetHeader?.budgetheaderstatusid?.toString() !== "4"} ref={register()} defaultValue={task?.completeddate} type="date" name={`${inputName}.completeddate`} id="" className=""/> */}
            {errors?.budget_lines?.[lineIndex]?.budget_line_tasks?.[taskIndex]?.completeddate &&
              <div className="error-cont">{errors?.budget_lines?.[lineIndex]?.budget_line_tasks?.[taskIndex]?.completeddate?.message}</div>
            }
          </>
        )}
      </td>
      {index === 0 &&
        <>
          <td  className="width-money">
            {(milestoneFee == null) ? (
              <div className="calc">
                -
              </div>
            ) : (
                <div className="calc">
                  {currencySymbol}  {parseFloat(milestoneFee)?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                </div>
            )}
          </td>
          <td  className="width-money">
            {(milestoneBilled  == null) ? (
              <div className="calc">
                -
              </div>
            ) : (
                <div className="calc">
                  {currencySymbol}  {parseFloat(milestoneBilled)?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                </div>
            )}
          </td>
          <td   className="width-money">

            {(milestonePaid == null) ? (
              <div className="calc">
                -
              </div>
            ) : (
                <div className="calc">
                  {currencySymbol} {parseFloat(milestonePaid)?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                </div>
            )}
          </td>
        </>
      
      }
      

      
      
    

    </React.Fragment>
  )
}


const mapState = state => ({
  projectUsers: state.projectUserState.data,
  budgetHeader: state.budgetHeaderState.data,
  budgetFees: state.budgetFeeState.data,
  budgetLines: state.budgetLineState.data,
});
export default connect(mapState)(BudgetTaskRow);
