import React, { useEffect, useState, useRef } from 'react';
import { currencies } from './expense/currencies';
// import TimesheetsNav from './TimesheetsNav';
import { useApi } from '../../api';
import { connect } from 'react-redux';
import { useForm, Controller, useWatch } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import {lazy, string, object} from 'yup';
// import { getBudgetHeader } from '../../redux/manager/budgets/budgetActions';
import { getMyProjects, getProject } from '../../redux/projects/projectActions';
import { getProjectUsers, createProjectUser, addProjectUser, removeProjectUser, setProjectUsers, deleteProjectUser, updateProjectUser, changeProjectUser } from '../../redux/manager/project_users/projectUserActions';
import { getUsers } from '../../redux/manager/users/userActions';
import { DateTime } from 'luxon';
import {
  Link,
  useParams,
  useNavigate,
} from 'react-router-dom';
import store from '../../redux/store';

import { cancelRevision, getReviewCount, saveBudgetHeader, clearBudgetHeader, addBudgetLineTask, getBudgetHeader, removeBudgetLineTask, createBudgetLineTask, deleteBudgetLineTask, createBudgetFee, submitBudgetHeader, returnBudgetHeader, approveBudgetHeader, reviseBudgetHeader } from '../../redux/manager/budgetHeaders/budgetHeaderActions';
// import { addBudgetTask, removeBudgetTask } from '../../redux/manager/budgetTasks/budgetTaskActions';
import { addBudgetFee, removeBudgetFee, setBudgetFees } from '../../redux/manager/budgetFee/budgetFeeActions';
import { create, reduceRight, sum } from 'lodash';
import BudgetTaskRow from './BudgetTaskRow';
import BudgetLineRow from './BudgetLineRow';
import BudgetFeeRow from './BudgetFeeRow';
import { setFullscreenLoading } from '../../redux/ui/uiActions';
import { getBudgetFeeCategories } from '../../redux/admin/budgetFeeCategoryActions';
import { getBudgetPhases } from '../../redux/admin/budgetPhaseActions';

import Modal from '../../components/Modal';
import BudgetRevisions from './BudgetRevisions';
import BudgetFeeRows from './BudgetFeeRows';
import DatePicker from 'react-date-picker';
import '../../styles/CalendarNew.scss';
import { isNumeric } from '../../utils';

import InterProjects from './InterProjects';

import { usePrevious } from '../../hooks/usePrevious';
import BudgetingProjectTotals from './BudgetingProjectTotals';
import BudgetProjectBalance from './BudgetProjectBalance';
import BudgetHoursBalance from './BudgetHoursBalance';
import BudgetCalcs from './BudgetCalcs';

function getLang() {
  if (navigator.languages != undefined) 
    return navigator.languages[0]; 
  return navigator.language;
}

const BudgetingProject = ({currentCompany, viewAsUser, projectUsers, budgetHeader}) => {

  const navigate = useNavigate('/');
  const prevCompany = usePrevious(currentCompany);

  useEffect(() => {
    if (prevCompany && currentCompany) {
      if (prevCompany !== currentCompany) {
        navigate("/budgeting")
      }
    }    

    
    if (currentCompany) {
      getExchangeRates(currentCompany.accountingcurrency, "company");
    }
  }, [currentCompany]);

  const [companyExchangeRates, setCompanyExchangeRates] = useState(null);
  const [projectExchangeRates, setProjectExchangeRates] = useState(null);
  const [project, projectLoading, projectLoaded, projectError, doProjectFetch, setProjectResult] = useApi();

  useEffect(() => {
    if (project) {
      getExchangeRates(project.salescurrency, "project");
    }
  }, [project]);

  const fetchExchangeRates = async (currency, type="project") => {
    if (!currency) return;


    const result = await fetch(`https://open.er-api.com/v6/latest/${currency}`);
    const json = await result.json();

    localStorage.setItem(`exchangeRates${currency}`, JSON.stringify(json));
    if (type === "project") {
      setProjectExchangeRates(json);
    }
    else if (type === "company") {
      setCompanyExchangeRates(json);
    }
    
  }

  const getExchangeRates = async (currency, type="project") => {
    if (!currency) return;

    // alert(`get ${type}`);

    const stored = localStorage.getItem(`exchangeRates${currency}`);
    
    if (stored) {

      const parsed = JSON.parse(stored);

      const millis = Date.now() - (parsed['time_last_update_unix']*1000.0);
      const hours = millis / (1000 * 60 * 60);

      

      if (hours >= 24) {
        fetchExchangeRates(currency);
      }
      else {

        // alert(type)
        if (type === "project") {
          setProjectExchangeRates(parsed);
        }
        else if (type === "company") {
          setCompanyExchangeRates(parsed);
        }
        
      }

    }
    else {
      fetchExchangeRates(currency);
    }
  }

  const { control, setValue, watch, unregister, register, handleSubmit, formState: {errors}, getValues, trigger, reset, clearErrors} = useForm({
    // validationSchema: SubmitBudgetSchema,
    // resolver: yupResolver(SubmitBudgetSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [hoursBalanceNegative, setHoursBalanceNegative] = useState(false);



  const calcTotalConsumedHours = () => {

    if (!budgetHeader?.budget_lines) return 0;

    let sum = 0;

    budgetHeader?.budget_lines.forEach(line => {

      const tasks = line.budget_line_tasks;

      if (!tasks) return;
      console.log("tasks", tasks);
      sum = sum + tasks.map(t => t.finalhoursconsumed).reduce((current, previous) => { return (parseFloat(current) + parseFloat(previous)) }, 0);
    })

    return sum;


  }


  const { projectid, revisionid } = useParams();

  useEffect(() => {
    store.dispatch(clearBudgetHeader());
    // fetchProject();
    // fetchProjectUsers();
    // fetchUsers();
    fetchBudgetHeader();
    fetchProject();
    //doReviewCountFetch(getReviewCount);

    return(() => {
      store.dispatch(clearBudgetHeader());
    });
  }, [projectid, revisionid]);

 
  
  const [projectUsersResult, projectUsersLoading, projectUsersLoaded, projectUsersError, doProjectUsersFetch, setProjectsUsersResult] = useApi();

  useEffect(() => {
    store.dispatch(setProjectUsers(projectUsersResult));
  }, [projectUsersResult]);

  const fetchProject = () => {
    doProjectFetch(getProject, {query: {id: projectid}});
  }
  const fetchProjectUsers = () => {
    doProjectUsersFetch(getProjectUsers, {query: {projectid: projectid}});
  }

  const [budgetHeaderResult, budgetHeaderLoading, budgetHeaderLoaded, budgetHeaderError, doBudgetHeaderFetch, setBudgetHeaderResult] = useApi();
  
  const fetchBudgetHeader = () => {
    doBudgetHeaderFetch(getBudgetHeader, {query: {revision_id: revisionid, project_id: projectid}});
  }

  const [newFeeName, setNewFeeName] = useState('');
  const [newFeeAmount, setNewFeeAmount] = useState('');
  const [newFeeCat, setNewFeeCat] = useState('');

  const [createdFee, createFeeLoading, createFeeLoaded, createFeeError, doCreateFeeFetch, setCreateFeeResult] = useApi();

  const addBudgetFeeClicked = () => {
    const payload = {
      id: null, 
      budgetheaderid: budgetHeader?.id, 
      budgetfeecategoryid: newFeeCat,
      amount: newFeeAmount,
      description: newFeeName
    }

    doCreateFeeFetch(createBudgetFee, {payload: payload})
  }
  useEffect(() => {
    if (createFeeLoaded) {
      setNewFeeName('');
      setNewFeeAmount('');
      setNewFeeCat('');
    }
  }, [createFeeLoaded]);


  const clearFields = () => {
    setNewFeeName('');
    setNewFeeAmount('');
  }

  const [newUserOpened, setNewUserOpened] = useState(true);

  const [savedBudgetHeader, saveBudgetHeaderLoading, saveBudgetHeaderLoaded, saveBudgetHeaderError, doSaveBudgetHeaderFetch, setSaveBudgetHeaderResult] = useApi();

  useEffect(() => {
    // store.dispatch(setFullscreenLoading(saveBudgetHeaderLoading));
  }, [saveBudgetHeaderLoading]);

  const [savedTeamBudgetHeader, saveTeamBudgetHeaderLoading, saveTeamBudgetHeaderLoaded, saveTeamBudgetHeaderError, doSaveTeamBudgetHeaderFetch, setSaveTeamBudgetHeaderResult] = useApi();
  
  
  const saveTeamButtonClicked = () => {


    let data = getValues();

    let payload = {
        budget_header: {
          team_users_attributes: data?.team_users,
          id: data.id,
          is_saving_team: true,
          projectid: budgetHeader.projectid,
        }
      }
    


    doSaveTeamBudgetHeaderFetch(saveBudgetHeader, {payload: payload})
  }

  useEffect(() => {
    // store.dispatch(setFullscreenLoading(saveBudgetHeaderLoading));

    if (saveTeamBudgetHeaderLoaded) {
      // navigate('/budgeting')
    }
  }, [saveTeamBudgetHeaderLoaded]);



  const isXProject = () => {
    if (!budgetHeader || budgetHeader.projectid.length === 0) return false;

    return budgetHeader.projectid.charAt(0).toUpperCase() === "X";
  }

  const lineHasTasks = (line) => {

    if (!line || !line.budget_line_tasks || line.budget_line_tasks.length === 0) return false;

    const nonDestroyed = line.budget_line_tasks.select(task => task._destroy !== "1");

    return nonDestroyed?.length !== 0;

  }

  const saveButtonClicked = async () => {
    let data = getValues();

    if (readOnly && data) {
      data = {
        team_users: data.team_users,
        id: data.id,
        is_saving_team: true,
        projectid: budgetHeader.projectid,
      }
    }

   
    // return
    //watch('budget_lines')
    let bla = [];

    if (data.budget_lines) {
      data.budget_lines.forEach(bl => {

        

        bla.push(
          {
            ...bl,
            budget_line_tasks_attributes: bl.budget_line_tasks
          }
        )



        
      })
    }


    let payload = {
      budget_header: {
        ...data,
        budget_lines_attributes: bla,
        budget_fees_attributes: data.budget_fees,
        team_users_attributes: data.team_users,
      }
    }
    delete payload.budget_header.budget_lines;
    delete payload.budget_header.budget_fees;
    delete payload.budget_header.team_users;



    doSaveBudgetHeaderFetch(saveBudgetHeader, {payload: payload})
  }

  const handleSubmitBudget = () => {
    

    // const data = getValues();

    // // alert(JSON.stringify(data));
    
    // // return
    // const payload = {
    //   note: submitNote,
    //   budget_header: {
    //     ...data
    //   }
    // }



    let data = getValues();

    if (readOnly && data) {
      data = {
        team_users: data.team_users,
        id: data.id,
        is_saving_team: true,
        projectid: budgetHeader.projectid,
      }
    }

   
    // return
    //watch('budget_lines')
    let bla = [];

    if (data.budget_lines) {
      data.budget_lines.forEach(bl => {

        


        bla.push(
          {
            ...bl,
            budget_line_tasks_attributes: bl.budget_line_tasks
          }
        )



        
      })
    }


    let payload = {
      budget_header: {
        ...data,
        budget_lines_attributes: bla,
        budget_fees_attributes: data.budget_fees,
        team_users_attributes: data.team_users,
      }
    }
    delete payload.budget_header.budget_lines;
    delete payload.budget_header.budget_fees;
    delete payload.budget_header.team_users;

    doSubmitBudgetHeaderFetch(submitBudgetHeader, {payload: payload});
  }

  const onSubmit = data => {
    const payload = {
      budget_header: {
        ...data
      }
    }
    doSubmitBudgetHeaderFetch(submitBudgetHeader, {payload: payload})
  }

  const [phases, phasesLoading, phasesLoaded, phasesError, doPhasesFetch, setPhasesResult] = useApi();
  
  useEffect(() => {
    fetchPhases();
  }, []);

  const fetchPhases = () => {
    doPhasesFetch(getBudgetPhases);
  }

  const [budgetFeeCategories, budgetFeeCategoriesLoading, budgetFeeCategoriesLoaded, budgetFeeCategoriesError, doBudgetFeeCategoriesFetch, setBudgetFeeCategoriesResult] = useApi();
  
  useEffect(() => {
    fetchBudgetFeeCategories();
  }, []);

  const fetchBudgetFeeCategories = () => {
    doBudgetFeeCategoriesFetch(getBudgetFeeCategories);
  }

  const clearEmpties = (o) => {
    for (var k in o) {
      if (!o[k] || typeof o[k] !== "object") {
        continue // If null or not an object, skip to the next iteration
      }
  
      // The property is an object
      clearEmpties(o[k]); // <-- Make a recursive call on the nested object
      if (Object.keys(o[k]).length === 0) {
        delete o[k]; // The object had no properties, so delete that property
      }
    }
  }


  const [submitNote, setSubmitNote] = useState(null);
  const [submitModalOpened, setSubmitModalOpened] = useState(false);
  const [errorModalOpened, setErrorModalOpened] = useState(false);
  const [submittedBudgetHeader, submitBudgetHeaderLoading, submitBudgetHeaderLoaded, submitBudgetHeaderError, doSubmitBudgetHeaderFetch, setSubmitBudgetHeaderResult] = useApi();

  const toggleErrorModal = () => {
    setErrorModalOpened(o => !o);
  }

  useEffect(() => {
    if (budgetHeader && saveBudgetHeaderLoaded) {

      reset({
        ...getValues(),
        budget_lines: budgetHeader.budget_lines,
        budget_fees: budgetHeader.budget_fees,
      })

    }
  }, [saveBudgetHeaderLoaded, budgetHeader]);

  const submitButtonClicked = async () => {
    const t = await trigger();
    
    if (t) {
      toggleSubmitModal();
    }
    else {
      toggleErrorModal()
    }
  
  }
  const toggleSubmitModal = () => {
    setSubmitModalOpened(o => !o);
  }

  useEffect(() => {
    if (submitBudgetHeaderLoaded) {
      navigate(`/budgeting`);
    }
  }, [submitBudgetHeaderLoaded]);



  const canMakeRevision = () => {
    // if (viewAsUser?.roleid === 2) {
    //   return true
    // }

    if (viewAsUser?.roleid === 4 || viewAsUser?.roleid === 1 || viewAsUser.roleid === 8 || viewAsUser.roleid === 9) {
      if (budgetHeader?.managerworkerrecid === viewAsUser.workerrecid || budgetHeader?.is_delegated_to_me) {
        return true;
      }
      else {
        return false;
      }
    }

    return false
  };

  const getReadOnly = () => {
    // if ((viewAsUser.roleid == "3") && (budgetHeader?.budgetheaderstatusid?.toString() === "4")) {
    //   // alert('here 3')
    //   return true;
    // }

    if (viewAsUser?.roleid === 2 || viewAsUser?.roleid === 3 || viewAsUser?.roleid === 4) {
    
      if (budgetHeader?.managerworkerrecid === viewAsUser.workerrecid || budgetHeader?.is_delegated_to_me) {
        // This is director/admin/proctor's budget

        // Proceed as if MANAGER role
        // alert('delegated')
      }
      else {
        return true;
      }
      
    } 
    else {
      // Someone else's budget: only read

      // alert('2')
      // alert('here 2')
      // return true;
      
    }
    if (!budgetHeader || revisionid || (budgetHeader?.budgetheaderstatusid?.toString() === "1") || (budgetHeader?.budgetheaderstatusid?.toString() === "2")) {
      // alert('here')
      return true;
    }

    return false;
  }

  const readOnly = getReadOnly();

  const disableButtons = !budgetHeader || saveBudgetHeaderLoading || submitBudgetHeaderLoading;

  // const submitButtonDisabled = isFormEdited;

  const showRevisions = budgetHeader && budgetHeader?.hasRevisions && budgetHeader?.budgetheaderstatusid?.toString() !== "0"; 
  
  const showButtons = !revisionid && !readOnly && budgetHeader && (budgetHeader?.budgetheaderstatusid?.toString() === "0" || budgetHeader?.budgetheaderstatusid?.toString() === "3" || budgetHeader?.budgetheaderstatusid?.toString() === "4" || budgetHeader?.budgetheaderstatusid?.toString() === "5");

  const showSaveButton = () => {
    if (revisionid) return false;

    return !readOnly;

    if ((viewAsUser.roleid == "2" || viewAsUser.roleid == "3")) {
      return true
    }
    else{
      return !readOnly;
    }
  }
  

  const [revisionModalOpened, setRevisionsModalOpened] = useState(false);
  const toggleRevisionModal = () => {
    setRevisionsModalOpened(o => !o);
  }

  const [approvedBudgetHeader, approveBudgetHeaderLoading, approveBudgetHeaderLoaded, approveBudgetHeaderError, doApproveBudgetHeaderFetch, setApproveBudgetHeaderResult] = useApi();

  const approveButtonClicked = () => {
    const payload = {
      id: budgetHeader.id,
      projectid: budgetHeader.projectid,
    }
    doApproveBudgetHeaderFetch(approveBudgetHeader, {payload: payload});
  }

  useEffect(() => {
    if (approveBudgetHeaderLoaded) {
      navigate(`/budgeting/review`);
    }
  }, [approveBudgetHeaderLoaded]);

  const [canceledRevision, cancelRevisionLoading, cancelRevisionLoaded, cancelRevisionError, doCancelRevisionFetch, setCancelRevisionResult] = useApi();

  const cancelRevisionButtonClicked = () => {
    const payload = {
      id: budgetHeader.id,
      projectid: budgetHeader.projectid,
    }
    doCancelRevisionFetch(cancelRevision, {payload: payload});
  }


  useEffect(() => {
    if (budgetHeader && cancelRevisionLoaded) {
      reset({
        ...getValues(),
        budget_lines: budgetHeader.budget_lines,
        budget_fees: budgetHeader.budget_fees
      })
    }
  }, [cancelRevisionLoaded, budgetHeader]);


  const [revisedBudgetHeader, reviseBudgetHeaderLoading, reviseBudgetHeaderLoaded, reviseBudgetHeaderError, doReviseBudgetHeaderFetch, setReviseBudgetHeaderResult] = useApi();

  const reviseButtonClicked = () => {
    const payload = {
      id: budgetHeader.id,
      projectid: budgetHeader.projectid,
    }

    // alert(JSON.stringify(payload))
    doReviseBudgetHeaderFetch(reviseBudgetHeader, {payload: payload});
  }

  useEffect(() => {
    if (reviseBudgetHeaderLoaded) {
      // navigate(`/budgeting`);
      // window.location.reload();
    }
  }, [reviseBudgetHeaderLoaded]);

  useEffect(() => {
    if (budgetHeader && reviseBudgetHeaderLoaded) {
      reset({
        ...getValues(),
        budget_lines: budgetHeader.budget_lines,
        budget_fees: budgetHeader.budget_fees
      })
    }
  }, [reviseBudgetHeaderLoaded, budgetHeader]);


  

  const [returnNote, setReturnNote] = useState(null);
  const [returnModalOpened, setReturnModalOpened] = useState(false);
  const [returnedBudgetHeader, returnBudgetHeaderLoading, returnBudgetHeaderLoaded, returnBudgetHeaderError, doReturnBudgetHeaderFetch, setReturnBudgetHeaderResult] = useApi();

  const returnButtonClicked = () => {
    toggleReturnModal();
  }

  const handleReturnBudget = () => {
    const payload = {
      note: returnNote,
      id: budgetHeader.id,
      projectid: budgetHeader.projectid,
    }
    doReturnBudgetHeaderFetch(returnBudgetHeader, {payload: payload});
  }

  const toggleReturnModal = () => {
    setReturnModalOpened(o => !o);
  }

  useEffect(() => {
    if (returnBudgetHeaderLoaded) {
      navigate(`/budgeting/review`);
    }
  }, [returnBudgetHeaderLoaded]);



  const disableReviseButton = disableButtons || reviseBudgetHeaderLoading;
  const disableApproveButton = disableButtons || approveBudgetHeaderLoading || returnBudgetHeaderLoading; 
  const disableReturnButton = disableButtons || approveBudgetHeaderLoading || returnBudgetHeaderLoading; 

  const statusBadgeClass = () => {
    return budgetHeader?.statusname?.toLowerCase();
  }

  const [feeCount, setFeeCount] = useState(0);
  const [feeRows, setFeeRows] = useState([]);
  const [removedFeeIndexes, setRemovedFeeIndexes] = useState([]);

  useEffect(() => {
    if (saveBudgetHeaderLoaded) {

      setFeeRows([]);

    }
  }, [saveBudgetHeaderLoaded])


  const addFeeRowClicked = () => {
    setFeeRows(o => [...o, setFeeCount+1]);
    setFeeCount(o => o+1);    
  }

  const removeFeeRowClicked = (row, feeFieldName) => {
    // unregister([`${feeFieldName}.amount`, `${feeFieldName}.description`, ])
    // setValue(`${feeFieldName}.amount`, '');
    // setValue(`${feeFieldName}.description`, '');
    // setValue(`${feeFieldName}.budgetfeecategoryid`, '');
    setValue(`${feeFieldName}`, null);
    setFeeRows(o => [...o.filter(e => e !== row)] );
  }

  
  const [hoursArray, setHoursArray] = useState([]);
  const [valuesArray, setValuesArray] = useState([]);

  const updateValues = (value, lineIndex, taskIndex) => {
    // alert(lineIndex)
    if (value && lineIndex && taskIndex) {
      setValuesArray(o => o[lineIndex][taskIndex] = value);
    }
    
  }



  const showReturnAndApproveButtons = () => {
    if (revisionid) return false;


    if (!viewAsUser || !budgetHeader) {
      return false;
    }
    if (viewAsUser.roleid == "2" || viewAsUser.roleid == "3") {
      if (budgetHeader?.budgetheaderstatusid?.toString() === "1") {
        return true
      }
    }
    else {
      return false;
    }

    return false
  }


  const [allTeamUsersSelected, setAllTeamUsersSelected] = useState(false);

  const selectAllTeamUsers = () => {

    if (budgetHeader?.team?.team_users) {
      budgetHeader.team.team_users.forEach((teamUser, i) => {
        setValue(`team_users.${i}.active`, !allTeamUsersSelected)
      })
    }

    setAllTeamUsersSelected(o => !o);
  }

  const canEditTeam = true;
  const isRevising = !revisionid && budgetHeader?.budgetheaderstatusid?.toString() === "4"; 

  const getCurrencySymbol = (code) => {
    if (!currencies) return "";

    const currencyObj = currencies[code];

    if (!currencyObj) return "";

    return currencyObj.symbol;
  }


  const activeProjectUsers = budgetHeader?.team?.project_users.filter(pu => pu.active === 1);

  const projectUserChecked = (userid) => {
    return (budgetHeader?.team?.project_users.filter(pu => pu.workerrecid === userid && pu.active === 1)).length !== 0
  }

  
  const hoursToName = (budgetHeader?.budgetheaderstatusid?.toString() === "0" ? "Hours to Complete" : "Hours to Revise");


  const isDelegatedToMe = () => {
    if (!budgetHeader) return false;

    return budgetHeader.is_delegated_to_me
  }

  const hasTasks = () => {
    const values = getValues();
  }

  const totalMilestoneFee = parseFloat(budgetHeader?.budget_lines?.reduce((a,b) => parseFloat(a) + parseFloat(b.milestonefee ? b.milestonefee : 0), 0));

  const totalMilestoneBilled = parseFloat(budgetHeader?.budget_lines?.reduce((a,b) => parseFloat(a) + parseFloat(b.milestonebilled ? b.milestonebilled : 0), 0));


  return (
    <>

      {isDelegatedToMe() &&
        <div style={{color: 'rgba(0,0,0,.45)', display: 'flex', alignItems: 'center',  padding: '.25em', background: 'rgba(0,0,0,0)'}}>
          <div> 
            <span className="bold uppercase font-08">Delegated by </span>
            {budgetHeader.original_pm_name}
          </div>
        </div>
    
      }



      {(saveBudgetHeaderLoading || saveTeamBudgetHeaderLoading) &&
        <Modal canClose={false} size="small" focusTrap={false} toggleModal={toggleErrorModal}>
          <div className="modal-header flex-cont align-center">
            {/* <h2 className="flex-1">Return Budget</h2> */}

            <div className="flex-1"></div>
            {/* <div onClick={toggleErrorModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div> */}
          </div>

          <div className="padding-1">
            Saving...
          </div>

          <div className='modal-footer flex-cont align-center'>
           
          </div>
        </Modal>
      }
      {revisionModalOpened &&
        <Modal focusTrap={false} toggleModal={toggleRevisionModal}>
          <BudgetRevisions toggleModal={toggleRevisionModal} budgetHeader={budgetHeader} projectid={projectid} />
        </Modal>
      }
      {errorModalOpened &&
        <Modal size="small" focusTrap={false} toggleModal={toggleErrorModal}>
          <div className="modal-header flex-cont align-center">
            {/* <h2 className="flex-1">Return Budget</h2> */}

            <div className="flex-1"></div>
            <div onClick={toggleErrorModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            There are some issues with your budget. Please fix the issues highlighted below and try again.
          </div>

          <div className='modal-footer flex-cont align-center'>
           
          </div>
        </Modal>
      }
      {returnModalOpened &&
        <Modal focusTrap={false} toggleModal={toggleReturnModal}>
          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">Return Budget</h2>
            <div onClick={toggleReturnModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            {budgetHeader?.returnnote &&
              <div className="margin-bottom-1">
                <span className="bold gray font-075">PREVIOUS RETURN NOTE: </span>
                <span> {budgetHeader?.returnnote}</span>
              </div>
            }
            <textarea placeholder="Return note" value={returnNote} onChange={e => setReturnNote(e.target.value)}   />
          </div>

          <div className='modal-footer flex-cont align-center'>
            <input onClick={handleReturnBudget} type="submit" value="Submit" className={`${returnBudgetHeaderLoading ? 'disabled' : ''} margin-right-1 button`} />
            {returnBudgetHeaderLoading &&
              <div className="spinner margin-right-1"></div>
            }
            <div onClick={toggleReturnModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      }
      {submitModalOpened &&
        <Modal focusTrap={false} toggleModal={toggleSubmitModal}>
          <div className="modal-header flex-cont align-center">
            <h2 className="flex-1">Submit Budget</h2>
            <div onClick={toggleSubmitModal} className="ghost button close">
              <i className="fas fa-times"></i>
            </div>
          </div>

          <div className="padding-1">
            {budgetHeader?.submitnote &&
              <div className="margin-bottom-1">
                <span className="bold gray font-075">PREVIOUS SUBMISSION NOTE: </span>
                <span> {budgetHeader?.submitnote}</span>
              </div>
            }
            {/* <textarea placeholder="Submission note" value={andy} onChange={e => setAndy(e.target.value)}   /> */}
            <textarea name="submit_note" id="submit_note" placeholder="Submission note" value={submitNote} onChange={e => setSubmitNote(e.target.value)}   />
          </div>

          <div className='modal-footer flex-cont align-center'>
            <input onClick={handleSubmitBudget} type="submit" value="Submit" className={`${submitBudgetHeaderLoading ? 'disabled' : ''} margin-right-1 button`} />
            {submitBudgetHeaderLoading &&
              <div className="spinner margin-right-1"></div>
            }
            <div onClick={toggleSubmitModal} className="button ghost">Cancel</div>
          </div>
        </Modal>
      }

      <form onSubmit={handleSubmit(onSubmit)}>

      <div className="flex-cont align-center margin-bottom-1">
        <h1 className="no-margin-vertical margin-right-1 font-1-5">Budget & Billing Worksheet</h1>

        {budgetHeader &&
          <div className="status-badge-cont">
            <div className={`${statusBadgeClass()} budget status-badge`}>
              {revisionid &&
                <>
                
                  <span className="margin-right-half">REVISION</span>



                  {budgetHeader?.approveddate &&
                    <>
                      <> {DateTime.fromISO(budgetHeader.approveddate).toLocaleString()} </>
                      {budgetHeader?.approvedbyuser && 
                        <> by {budgetHeader?.approvedbyuser} </>
                      }
                    </>
                  }
                </>
              }
              {!revisionid &&
                <>
                  {budgetHeader?.statusname} 

                  {budgetHeader?.budgetheaderstatusid?.toString() === "2" &&
                    <>
                      <> {DateTime.fromISO(budgetHeader.approveddate).toLocaleString()} </>
                      {budgetHeader?.approvedbyuser && 
                        <> by {budgetHeader?.approvedbyuser} </>
                      }
                    </>
                  }
                </>
              }
              
            </div>
          </div>
        }

        <div className="flex-1"></div>

          {showRevisions &&
            <>
              <div onClick={toggleRevisionModal} className="button ghost margin-right-half margin-left-1">Revisions</div>
            </>
          }

          {false && isRevising &&
            <>
              {/* <div onClick={cancelRevisionClicked} className="button outline margin-right-half margin-left-1">Cancel Revision</div> */}

              <div onClick={cancelRevisionButtonClicked} className={`${cancelRevisionLoading ? 'disabled' : ''} margin-left-1 button`}>
                Cancel Revision
                {cancelRevisionLoading &&
                  <div className="spinner button-spinner"></div>
                }
              </div>
            </>
          }


          {!revisionid && showSaveButton() &&

            <div onClick={saveButtonClicked} className={`${(disableButtons) ? 'disabled' : ''} outline button`}>
              Save
              {saveBudgetHeaderLoading &&
                <div className="spinner button-spinner"></div>
              }
            </div>
          }

          

          {budgetHeader && (budgetHeader?.budgetheaderstatusid?.toString() === "2") && canMakeRevision() &&
            <>
              <div onClick={reviseButtonClicked} className={`${disableReviseButton ? 'disabled' : ''} margin-left-1 button margin-right-half`}>
                Make Revision
                {reviseBudgetHeaderLoading &&
                  <div className="spinner button-spinner"></div>
                }
              </div>

            </>
          }

          {showReturnAndApproveButtons() &&
            <>
            <div onClick={returnButtonClicked} className={`${disableReturnButton ? 'disabled' : ''} margin-left-1 button`}>
              Return
              {returnBudgetHeaderLoading &&
                <div className="spinner button-spinner"></div>
              }
            </div>
            <div onClick={approveButtonClicked} className={`${disableApproveButton ? 'disabled' : ''} margin-left-1 button`}>
              Approve
              {approveBudgetHeaderLoading &&
                <div className="spinner button-spinner"></div>
              }
            </div>
            </>
          }

          

          {showButtons &&
            <>

              

              <div onClick={submitButtonClicked} className={`${(disableButtons) ? 'disabled' : ''} margin-left-1 button`}>
                  Save & Submit
                {submitBudgetHeaderLoading &&
                  <div className="spinner button-spinner"></div>
                }
              </div>

              {/* <button disabled={disableButtons} type="submit" className="margin-left-1 button">
                  Save & Submit
                {submitBudgetHeaderLoading &&
                  <div className="spinner button-spinner"></div>
                }
              </button> */}

              {/* <div className="code">
                <pre>
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </div> */}
              
            </>
          }
      </div>

      {budgetHeader?.budgetheaderstatusid?.toString() === "5" && budgetHeader?.returnnote &&
        <div className="danger section margin-bottom-1">
          <span className="font-075 bold">RETURN NOTE: </span> {budgetHeader?.returnnote}
        </div>
      }
      {budgetHeader?.budgetheaderstatusid?.toString() === "1" && budgetHeader?.submitnote &&
        <div className="section margin-bottom-1">
          <span className="font-075 bold">SUBMISSION NOTE: </span> {budgetHeader?.submitnote}
        </div>
      }

      {/* <div className="section padding-1">
        <div className="flex-cont">
          <div className="code margin-right-1">
            <pre>{JSON.stringify(projectExchangeRates, null, 2)}</pre>
          </div>
          <div className="code margin-right-1">
            <pre>{JSON.stringify(companyExchangeRates, null, 2)}</pre>
          </div>
        </div>
      </div> */}
      

      {(budgetHeaderLoading || projectLoading ) && <>
          {/* <div className="spinner"></div> */}

          <div className="flex-cont full margin-top-2">
            <div style={{height: "calc(207px - 2em)"}} className="loading-gradient section flex-1 margin-right-2"></div>
            <div  style={{height: "calc(207px - 2em)"}}  className="loading-gradient section flex-1"></div>
          </div>

          <div  style={{height: "calc(400px - 2em)"}}  className="margin-top-2 loading-gradient section flex-1"></div>
          

        </>
      }

      {budgetHeader && project && (!(budgetHeaderLoading || projectLoading)) &&
        <>
        <input type="hidden" name="id" {...register("id")} defaultValue={budgetHeader.id}  />
        <input type="hidden" name="projectid" {...register("projectid")} defaultValue={budgetHeader.projectid}  />
        <div className="flex-cont full">

          <div className=" section flex-1 margin-right-2">
            <table className="vertical">
              
              <tr>
                <th>Project</th>
                <td>
                  {project.name}
                </td>
              </tr>
              <tr>
                <th>Project No.</th>
                <td>{budgetHeader.projectid}</td>
              </tr>
              <tr>
                <th>Company</th>
                <td>{budgetHeader.company}</td>
              </tr>
              <tr>
                <th>Estimated Profit</th>
                <td>
                    <div className="flex-cont">
                    <div className='relative flex-cont align-center'>
                      <input  {...register("estprofitpct", {
                        validate: v => {
                          if (!v) return "Enter a number."
                          if (!isNumeric(v)) return "Enter a number."
                          if (parseFloat(v) <= 0) return "Enter a number greater than 0."
                          return true;
                        }
                      })}  name="estprofitpct" defaultValue={budgetHeader.estprofitpct} type="text"  className={`${(readOnly) && 'disabled-input small'}`}  style={{paddingRight: '2em'}} />
                      
                      <div  style={{position: 'absolute', right: '8px'}}>
                        %
                      </div>
                    </div>
                    

                  </div>
                 
                  {errors?.estprofitpct && <div className="error-cont">{errors?.estprofitpct?.message}</div>}
                </td>
              </tr>
             
              <tr>
                <th>Project Start Date</th>
                <td>
                  <div className="input-font">
                    {DateTime.fromISO(project.actualStartDate).toLocaleString()}
                  </div>
                  
                </td>
              </tr>
              <tr>
                <th>Target Completion</th>
                <td>
                  {/* Date: {DateTime.now().toLocaleString()} */}

                  {/* <div className="button" onClick={() => alert(JSON.stringify(getValues('targetdate', null, 2)))}></div> */}
                  <input 
                    type="date" 
                    defaultValue={budgetHeader?.targetdate}
                    // disabled={readOnly} 
                    // || DateTime.now().toISODate()
                    {...register('targetdate', {
                      validate: v => {
                        if (!v) return "Enter a date."
                        if (!DateTime.fromISO(v)) return "Enter a date."
                        if (project?.actualStartDate && DateTime.fromISO(project.actualStartDate) && DateTime.fromISO(v) <= DateTime.fromISO(project.actualStartDate) ) {
                          return "Target completion must be after the project's start date."
                        }



                        return true;
                      }
                    })}
                    className={`${(readOnly) && 'disabled-input'}`}
                  />
                  {errors?.targetdate && <div className="error-cont">{errors?.targetdate?.message}</div>}
                </td>
              </tr>

              <tr>
                <th>Average Hourly Rate</th>
                <td>
                  <div className="relative flex-cont align-center">
                    <div  style={{position: 'relative', left: '0px', paddingRight: '2px'}}>
                      {getCurrencySymbol(project?.salescurrency)}
                    </div>
                    <input defaultValue={budgetHeader?.avghourlyrate } style={{paddingLeft: '0em'}} {...register("avghourlyrate", {
                      validate: v => {
                        if (!v) return "Enter a number."
                        if (!isNumeric(v)) return "Enter a number."
                        if (parseFloat(v) <= 0) return "Enter a number greater than 0."
                        return true;
                      }
                    })} type="text" name="avghourlyrate" id="" className={`${(readOnly) && 'disabled-input small'}`} />
                    
                  </div>
                  {errors?.avghourlyrate && <div className="error-cont">{errors?.avghourlyrate?.message}</div>}
                  
                </td>
              </tr>

            </table>
          </div>
          {true &&
          <BudgetCalcs setHoursBalanceNegative={setHoursBalanceNegative} budgetFeeCategories={budgetFeeCategories} budgetHeader={budgetHeader} getCurrencySymbol={getCurrencySymbol} defaultLines={budgetHeader.budget_lines} control={control} currency={project?.salescurrency} />


          }
        </div>

       
 
        <>

        {/* <pre>{watchedHours}</pre> */}
        <div style={{minWidth: '1377px'}} className="better section margin-top-2 margin-bottom-1 scroll">
          <table className="basic budget-worksheet wide">

            <tbody>
              <tr>
                <th className="width-phase">Phase</th>
                <th className="width-milestone">Milestone</th>
                <th className="width-taskname">Task</th>
    
                <th className="width-hours">
                  Hours Budgeted
                </th>
                <th className="width-hours">Hours Consumed</th>
                <th className="width-hours">Hours Pending</th>
                <th className="width-hours">Hours Balance</th>
                <th className={`width-hours ${hoursBalanceNegative && 'negative-red'}`}>
                  {hoursToName}
                  {/* {JSON.stringify(hoursBalanceNegative, null, 2)} */}
                </th>
                <th  className="width-date">Est. completion date</th>
                <th  className="width-hours">% Completed</th>
                <th  className="width-hours">% Billed</th>
                <th className="width-date">Date completed</th>
                <th className="width-money">Milestone Fee</th>
                <th className="width-money">Milestone Billed</th>
                <th className="width-money">Milestone Paid</th>
                
                
              </tr>

              {budgetHeader?.budget_lines?.map((line, index) => 
                <BudgetLineRow  clearErrors={clearErrors} currencySymbol={getCurrencySymbol(project?.salescurrency)}  stats={budgetHeader?.stats} balanceNegative={hoursBalanceNegative} reset={reset} updateValues={updateValues} control={control} saveBudgetHeaderLoaded={saveBudgetHeaderLoaded} getValues={getValues} setValue={setValue} readOnly={readOnly} watch={watch} budgetPhases={phases} rrr={() => true} trigger={trigger} lineErrors={errors?.budget_lines?.[index]} errors={errors}  lineIndex={index} unregister={unregister} register={register} line={line} />
              )}
            </tbody>

            <tbody>
              <tr>
              <td colSpan={2}></td>
              <td>
                <div style={{textAlign: 'right', color: 'gray', fontSize: '12px'}}>
                  TOTALS:
                </div>
              </td>
              <td>

                <div style={{fontSize: '12px', paddingLeft: '8px'}} className="bold">
                  {budgetHeader?.originalhourssum}
                </div>
              </td>
              <td>
                <div style={{fontSize: '12px', paddingLeft: '8px'}} className="bold">
                    {calcTotalConsumedHours()}
    

                  
                  
         
                </div>
              </td>
              <td>
                <div style={{fontSize: '12px', paddingLeft: '8px'}} className="bold">
                  {/* CCC:  */}
            
                  {budgetHeader?.hourspendingsum}
                </div>
              </td>
              {budgetHeader && 
                <BudgetingProjectTotals totalMilestoneFee={totalMilestoneFee} totalMilestoneBilled={totalMilestoneBilled} consumed={calcTotalConsumedHours()} control={control} defaultLines={budgetHeader.budget_lines} />
              }
              

              <td>
                <div style={{fontSize: '12px', paddingLeft: '8px'}} className="bold">
                  {getCurrencySymbol(project?.salescurrency)} {totalMilestoneFee.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                </div>
              </td>

              

              <td>
                <div style={{fontSize: '12px', paddingLeft: '8px'}} className="bold">
                  {getCurrencySymbol(project?.salescurrency)} {totalMilestoneBilled.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                </div>
              </td>

              <td>
                <div style={{fontSize: '12px', paddingLeft: '8px'}} className="bold">
                  {getCurrencySymbol(project?.salescurrency)}  {parseFloat(budgetHeader?.budget_lines?.reduce((a,b) => parseFloat(a) + parseFloat(b.milestonepaid ? b.milestonepaid : 0), 0)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                </div>
              </td>
              </tr>
            </tbody>

          </table>
        </div>
        </>
        


        {true && 
          <>
            <div className="section margin-bottom-1 scroll">
              <h3 className="margin-top-0">Other Costs</h3>
              <BudgetFeeRows currencySymbol={getCurrencySymbol(project?.salescurrency)} budgetPhases={phases} getValues={getValues} reset={reset} register={register} budgetFeeCategories={budgetFeeCategories} budgetHeader={budgetHeader} saveBudgetHeaderLoaded={saveBudgetHeaderLoaded} setValue={setValue} trigger={trigger} readOnly={readOnly} watch={watch} errors={errors} />
            </div>
          </>
        }


        {true &&
        <div className="section margin-bottom-1 scroll">
          <h3 className="margin-top-0">Intercompany Projects</h3>

          {/* {process.env.REACT_APP_DEPLOYMENT_SLOT !== "prod" && 
            <InterProjects projectid={projectid} />
          } */}
          <InterProjects projectid={projectid} />
        </div>
        }

        {true &&
          <div className="section">
            <h3 className="margin-top-0">Team</h3>

            {!budgetHeader?.team &&
              <em>No team found.</em>
            }
            {budgetHeader?.team &&
              <div>

                {!revisionid && canEditTeam &&
                  <div onClick={() => saveTeamButtonClicked()} className={`outline button`}>
                    Save Team
                    {saveBudgetHeaderLoading &&
                      <div className="spinner button-spinner"></div>
                    }
                  </div>
                }


                <table className="basic">
                  <tr>
                    <th>ROLE</th>
                    <th>NAME</th>
                    <th>ACTIVE</th>
                    <th>
                      {canEditTeam &&
                        <div onClick={selectAllTeamUsers} className="button ghost font-75">
                          Select All
                        </div>
                      }
                    </th>
                  </tr>
                  <tr>
                    <td className="fit font-075"><strong>MANAGER</strong></td>
                    <td>  
                      {budgetHeader?.team?.teamname}
                    </td>
                    <td></td>
                    <td className="full"></td>
                  </tr>

                  {budgetHeader?.team?.team_users?.map((teamUser, i) =>
                      <tr>
                        <td className="fit font-075 bold gray">MEMBER</td>
                        <td className="fit nowrap">{teamUser.userfname} {teamUser.userlname}</td>
                        <td className="fit nowrap">
                          {canEditTeam && <>
                              <input type="hidden" {...register(`team_users.${i}.company`)} name={`team_users.${i}.company`} defaultValue={budgetHeader.company}/>
                              <input type="hidden" {...register(`team_users.${i}.projectid`)} name={`team_users.${i}.projectid`} defaultValue={budgetHeader.projectid}/>
                              <input type="hidden" {...register(`team_users.${i}.id`)} name={`team_users.${i}.id`} defaultValue={teamUser.id}/>
                              <input disabled={!canEditTeam}  {...register(`team_users.${i}.active`)} defaultChecked={projectUserChecked(teamUser.userid)} type="checkbox" name={`team_users.${i}.active`} id=""/>
                            </>
                          }
                          {!canEditTeam &&
                            <>
                              {teamUser.project_user?.active?.toString() === "1" &&

                                <div className="bold font-075">
                                  ACTIVE
                                </div>
                              }

                            </>
                          }
                        </td>
                        <td className="full"></td>
                      </tr>
                    )
                  }
                </table>
              </div>
            }
          </div>
        }
      </>
      
      }

    </form>

    



      
    </>
  )
}




const mapState = state => ({
  projectUsers: state.projectUserState.data,
  budgetHeader: state.budgetHeaderState.data,
  // budgetFees: state.budgetHeaderState?.data?.budgetFees,
  budgetLines: state.budgetLineState.data,
  viewAsUser: state.authState.viewAsUser,
  currentCompany: state.authState.currentCompany,
});
export default connect(mapState)(BudgetingProject);
