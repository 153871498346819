import { useState } from "react";
import { useWatch } from "react-hook-form";


const BudgetingProjectTotals = ({totalMilestoneFee=0, totalMilestoneBilled=0, control, defaultLines, consumed=0}) => {

  const watchedLines = useWatch({
    control,
    name: 'budget_lines',
    defaultValue: defaultLines
  });

  const hoursArray = () => {

    const array = watchedLines;
    if (!array || array.length === 0) return [];

    let valueArray = [];

    array.forEach(line => {

      const tasks = line.budget_line_tasks;

      if (!tasks) return;
      tasks.forEach(task => {
        const hh = task.hoursrequired?.toString().replace(/,/g, '');
        const hours = parseFloat(hh);
        valueArray.push(hours);
      })
    })

    return valueArray;
  }

  const hoursSum = hoursArray()?.reduce((current, previous) => { return (current + previous) }, 0);

  const balanceSum = hoursSum - consumed;
  const balanceNegative = balanceSum < 0;


  return (
    <>
      <td>
        <div style={{fontSize: '12px', paddingLeft: '8px'}} className="bold">
          {balanceSum}
        </div>
      </td>
      <td  className={`${balanceNegative ? 'negative-red' : ''}`}>
        <div style={{fontSize: '12px', paddingLeft: '8px'}} className="bold">
          {hoursSum}
        </div>
      </td>

      <td colSpan={1}></td>

      <td>
        <div style={{fontSize: '12px', paddingLeft: '8px'}} className="bold">
          {Math.round(((parseFloat(consumed) / parseFloat(hoursSum))*100) * 10) / 10}%
        </div>
      </td>
      <td>
        <div style={{fontSize: '12px', paddingLeft: '8px'}} className="bold">
          {Math.round(((parseFloat(totalMilestoneBilled) / parseFloat(totalMilestoneFee))*100) * 10) / 10}%
          
        </div>
      </td>

      <td colSpan={1}></td>
    </>

  )

}

export default BudgetingProjectTotals;